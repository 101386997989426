import Profile from "./Profile";
import React from "react";

function App() {
	return (
		<div className="">
			<Profile></Profile>
		</div>
	);
}

export default App;
